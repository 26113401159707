@import "./tools";
@import "./mixins";

:root {
  --font-heading-regular: televoice-regular, sans-serif;
  --font-heading-medium: televoice-semibold, sans-serif;
  --font-body: opensans, helvetica, arial, sans-serif;

  --font-weight-normal: 400;
  --font-weight-medium: 600;
  --font-weight-bold: 700;

  --font-size-xs: pxToRem(10);
  --font-size-sm: pxToRem(14);
  --font-size-base: pxToRem(18);
  --font-size-lg: pxToRem(24);
  --font-size-xl: pxToRem(32);
  --font-size-2xl: pxToRem(44);
  --font-size-3xl: pxToRem(54);
  --font-size-4xl: pxToRem(72);

  /*
  --font-size-xs: pxToRem(8);
  --font-size-xs: pxToRem(10);
  --font-size-xs: pxToRem(12);

  --font-size-xs: pxToRem(14);
  --font-size-xs: pxToRem(16);
  --font-size-xs: pxToRem(18);

  --font-size-xs: pxToRem(24);
  --font-size-xs: pxToRem(28);

  --font-size-xs: pxToRem(30);
  --font-size-xs: pxToRem(32);
  --font-size-xs: pxToRem(36);

  --font-size-xs: pxToRem(40);
  --font-size-xs: pxToRem(44);
  --font-size-xs: pxToRem(48);

  --font-size-xs: pxToRem(50);
  --font-size-xs: pxToRem(54);
  --font-size-xs: pxToRem(60);

  --font-size-xs: pxToRem(72);
  */
}

html[lang="zh"] {
  --font-body: notosanssc, helvetica, arial, "Microsoft YaHei New",
    "Microsoft Yahei", "微软雅黑", 宋体, simsun, stxihei, "华文细黑", sans-serif;
  --font-heading-regular: var(--font-body);
  --font-heading-medium: var(--font-body);
}

html[lang="ja"] {
  --font-body: notosansjp, meiryo, helvetica, arial, sans-serif;
  --font-heading-regular: var(--font-body);
  --font-heading-medium: var(--font-body);

  // Experimental solution to handle line breaks in Japanese text
  word-break: auto-phrase;
}

body {
  font-family: var(--font-body);
  font-size: 1.125rem; /* 18px */
  font-weight: var(--font-weight-normal);
  color: var(--foreground-2);
}

strong {
  font-weight: var(--font-weight-bold);
  color: var(--black);
}

blockquote {
  font-family: var(--font-heading-medium);
  font-size: pxToRem(30);
}

label,
.label {
  font-style: normal;
  font-weight: var(--font-weight-medium);
  font-size: pxToRem(10);
  line-height: pxToRem(14);
  letter-spacing: 0.05em;
  text-transform: uppercase;
}

.label {
  font-family: var(--font-body);
}

@font-face {
  font-family: Televoice-Regular;
  src: url("../fonts/TeleVoice-Regular.woff2"),
    url("../fonts/TeleVoice-Regular.woff"),
    url("../fonts/TeleVoice-Regular.otf");
  font-display: swap;
}

@font-face {
  font-family: Televoice-Semibold;
  src: url("../fonts/TeleVoice-Semibold.woff2"),
    url("../fonts/TeleVoice-Semibold.woff"),
    url("../fonts/TeleVoice-Semibold.otf");
  font-display: swap;
}

@font-face {
  font-family: OpenSans;
  src: url("../fonts/OpenSans-Regular.woff2"),
    url("../fonts/OpenSans-Regular.woff");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: OpenSans;
  src: url("../fonts/OpenSans-SemiBold.woff2"),
    url("../fonts/OpenSans-SemiBold.woff");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: OpenSans;
  src: url("../fonts/OpenSans-Bold.woff2"), url("../fonts/OpenSans-Bold.woff");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: NotoSansSC;
  src: url("../fonts/NotoSansSC-Regular.woff2"),
    url("../fonts/NotoSansSC-Regular.woff");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: NotoSansSC;
  src: url("../fonts/NotoSansSC-Medium.woff2"),
    url("../fonts/NotoSansSC-Medium.woff");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: NotoSansSC;
  src: url("../fonts/NotoSansSC-Bold.woff2"),
    url("../fonts/NotoSansSC-Bold.woff");
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: NotoSansJP;
  src: url("../fonts/NotoSansJP-Regular.woff2"),
    url("../fonts/NotoSansJP-Regular.woff");
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: NotoSansJP;
  src: url("../fonts/NotoSansJP-Medium.woff2"),
    url("../fonts/NotoSansJP-Medium.woff");
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: NotoSansJP;
  src: url("../fonts/NotoSansJP-Bold.woff2"),
    url("../fonts/NotoSansJP-Bold.woff");
  font-weight: 700;
  font-display: swap;
}
