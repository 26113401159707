:root {
  box-sizing: border-box;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

html,
body {
  margin: 0;
  padding: 0;
  height: 100%;
}

input,
textarea,
select,
button {
  color: inherit;
  font: inherit;
}

a,
button,
input[type="submit"],
input[type="button"] {
  cursor: pointer;
  outline: none;

  &[disabled] {
    cursor: not-allowed;
  }
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

iframe {
  border: 0;
}
